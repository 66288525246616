.shorts-area {
  position: relative;
  padding-bottom: 3rem;
  border-bottom: 4px solid var(--second-bg);
}
.shorts-logo {
  display: flex;
  align-items: center;
  padding: 2rem 0;
}
.shorts-logo svg {
  width: 24px;
  margin-right: 0.25rem;
}
.shorts-logo span {
  font-size: 20px;
}
.short-boxes {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 1.5rem 1rem;
}
.short-box {
  display: flex;
  max-width: 220px;
  height: 100%;
  flex-direction: column;
  cursor: pointer;
}
.short-box img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-color: var(--third-bg);
  object-fit: cover;
}
.short-title {
  font-size: 1rem;
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
  padding-bottom: 0.5rem;
  margin-top: 0.5rem;
}
.short-views {
  font-size: 14px;
}
@media screen and (max-width: 1600px) {
  .short-boxes {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (max-width: 1128px) {
  .short-boxes {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 700px) {
  .short-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
