.posts-area {
  position: relative;
  margin-top: 1rem;
  padding-bottom: 3rem;
  border-bottom: 4px solid var(--second-bg);
  z-index: 1;
}
.post-boxes {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
}
.post-box {
  width: 100%;
  max-width: 360px;
  margin: 0 auto;
  cursor: pointer;
}
.post-img {
  position: relative;
}
.post-img img {
  width: 100%;
  border-radius: 0.5rem;
  object-fit: cover;
}
.post-time {
  position: absolute;
  bottom: 0.75rem;
  right: 0.5rem;
}
.post-time span {
  font-size: 10px;
  background-color: #0f0f0f;
  color: #fff;
  padding: 0.25rem;
  border-radius: 0.25rem;
}
.post-detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 0.5rem;
  margin-top: 0.5rem;
}
.post-info {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}
.post-texts {
  display: flex;
  flex-direction: column;
}
.post-user img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}
.post-title {
  font-weight: 500;
  color: var(--light-text);
  font-size: 1rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.channel-name {
  display: flex;
  align-items: center;
  color: var(--gray-text);
  font-size: 14px;
  text-decoration: none;
}
.channel-name i {
  font-size: 16px;
  padding-left: 0.25rem;
}
.channel-name:hover {
  color: var(--light-text);
}
.watched {
  color: var(--gray-text);
  font-size: 14px;
  margin-top: 0.25rem;
}
.watched span:nth-child(2)::before {
  content: "•";
  margin: 0 8px 0 4px;
}
.post-options {
  position: relative;
}
.post-options i {
  padding: 0.5rem;
  border-radius: 50%;
  cursor: pointer;
}
.post-options i:hover {
  background-color: var(--third-bg);
}
.options-menu {
  position: absolute;
  right: 0;
  width: 160px;
  padding: 0.5rem 0;
  border-radius: 0.5rem;
  background-color: var(--second-bg);
  display: flex;
  flex-direction: column;
  z-index: 9;
}
.options-menu a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
}
.options-menu a:hover {
  background-color: var(--third-bg);
}
.options-menu a i {
  padding: 0 0.75rem 0 0;
}
@media screen and (max-width: 1460px) {
  .post-boxes {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 1130px) {
  .post-boxes {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 720px) {
  .post-boxes {
    grid-template-columns: repeat(1, 1fr);
  }
}
